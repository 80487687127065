.header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /* Three equal columns */
    width: 100%;
    /* Span the full width of the page */
}

.column {
    border: 1px solid #ddd;
    /* Example border for columns */
    padding: 10px;
    text-align: center;
}