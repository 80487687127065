.eye {
    position: absolute;
    margin-top: -40px;
    display: flex;
    height: 325px;
    justify-content: center;
}

.eye img {
    transform-origin: center;
    transition: transform 3s;
}