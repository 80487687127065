.App {
  width: 100%;
  align-items: center;
  position: fixed;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: white;
}

.App-header img {
  width: 100px;
  height: 100px;
  margin: 20px;
  cursor: pointer;
}

.centered-components {
  display: flex;
  justify-content: center;
  align-items: center;
}

.beard {
  position: relative;
  height: 100vh;
}

/* .eye {
  position: absolute;
  margin-top: -40px;
  display: flex;
  height: 325px;
} */